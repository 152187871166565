import React, { Component } from 'react'
import { Card, CardBody, CardHeader, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Select from 'react-select';
import { createShipment, addAddress, getAddress, getConsigneeDetails, getLastShipments, validateUserAdCodes, getCountryData } from '../../api/Form'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css'
import { getLocation, makeid, tsid, trim, phoneNoVerifyCheck } from '../../utils/utils';
import { calculateCBM, averageWeight, calculateCVW } from '../../utils/businessLogic';
import close from '../../assets/close.svg'
import '../CreateMultipleShipments/CreateMultipleShipments.css'
import { ClipLoader } from "react-spinners";
import Modals from './Modal'
import SelectShipmentModal from './SelectShipmentModal';
import AddressBook from './AddressBook';
import { currencyList } from './CurrencyList';
import classnames from 'classnames';
import pdfImage from '../../assets/pdf.png';
import downloadIcon from '../../assets/doc.png';
import { callActivityApi } from '../../utils/saveUserActivity';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css'
import currenySymbol from "../Invoice/currencies.json"
import PhoneInput from 'react-intl-tel-input';
import { isValidNumber } from 'libphonenumber-js';
import 'react-intl-tel-input/dist/main.css';
import { cityList } from "../MasterNumber/IATAcities"
import { seaPort } from "../MasterNumber/SeaPortCodes"
import Axios from 'axios';

const Schema = Yup.object().shape({
	nfcADcode: Yup.string()
		.required('NfcADcode is required'),
	quantity: Yup.string()
		.required('Quantity is required'),
	refno: Yup.string()
		.required('Tracking no is required'),
	trackingstatus: Yup.string()
		.required('Tracking Status is required'),
	packageType: Yup.string()
		.required('Package type is required'),
	modeOfTransport: Yup.string()
		.required('Mode of transport is required'),
	// comment: Yup.string()
	// 	.required('comment is required'),
	origin: Yup.string()
		.required('Origin is required'),
	destination: Yup.string()
		.required('Destination is required'),
	carrierName: Yup.string()
		.required('Carrier Name is required'),
	deliveryAddress: Yup.string()
		.required('Delivery Address is required'),
	pickup: Yup.string()
		.required('Pickup is required'),
	origin_country: Yup.string()
		.required('Origin Country is required'),
	destination_country: Yup.string()
		.required('Destination Country is required'),
	emailAddress1: Yup.string()
		.email('Invalid Email'),
	shipperEmail1: Yup.string()
		.email('Invalid Email'),
	receiverEmail: Yup.string()
		.email('Invalid Email'),
	adCodes: Yup
		.string()
		.when("HideAdCodes", {
			is: true,
			then: Yup.string()
				.required("Ad Codes are required")
		}),
	receiverPhoneNumber: Yup.string()
		.required('Receiver Phone Number is required'),
	packageCurrency: Yup.string()
		.required('Currency is required'),

});

const options = [
	{ value: 'Afghanistan', label: 'Afghanistan' },
	{ value: 'Aland Islands', label: 'Aland Islands' },
	{ value: 'Albania', label: 'Albania' },
	{ value: 'Algeria', label: 'Algeria' },
	{ value: 'American Samoa', label: 'American Samoa' },
	{ value: 'AndorrA', label: 'AndorrA' },
	{ value: 'Angola', label: 'Angola' },
	{ value: 'Anguilla', label: 'Anguilla' },
	{ value: 'Antarctica', label: 'Antarctica' },
	{ value: 'Antigua and Barbuda', label: 'Antigua and Barbuda' },
	{ value: 'Argentina', label: 'Argentina' },
	{ value: 'Armenia', label: 'Armenia' },
	{ value: 'Aruba', label: 'Aruba' },
	{ value: 'Australia', label: 'Australia' },
	{ value: 'Austria', label: 'Australia' },
	{ value: 'Azerbaijan', label: 'Azerbaijan' },
	{ value: 'Bahamas', label: 'Bahamas' },
	{ value: 'Bahrain', label: 'Bahrain' },
	{ value: 'Bangladesh', label: 'Bangladesh' },
	{ value: 'Barbados', label: 'Barbados' },
	{ value: 'Belarus', label: 'Belarus' },
	{ value: 'Belgium', label: 'Belgium' },
	{ value: 'Belize', label: 'Belize' },
	{ value: 'Benin', label: 'Benin' },
	{ value: 'Bermuda', label: 'Bermuda' },
	{ value: 'Bhutan', label: 'Bhutan' },
	{ value: 'Bolivia', label: 'Bolivia' },
	{ value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
	{ value: 'Botswana', label: 'Botswana' },
	{ value: 'Bouvet Island', label: 'Bouvet Island' },
	{ value: 'Brazil', label: 'Brazil' },
	{ value: 'British Indian Ocean Territory', label: 'British Indian Ocean Territory' },
	{ value: 'Brunei Darussalam', label: 'Brunei Darussalam' },
	{ value: 'Bulgaria', label: 'Bulgaria' },
	{ value: 'Burkina Faso', label: 'Burkina Faso' },
	{ value: 'Burundi', label: 'Burundi' },
	{ value: 'Cambodia', label: 'Cambodia' },
	{ value: 'Cameroon', label: 'Cameroon' },
	{ value: 'Canada', label: 'Canada' },
	{ value: 'Cape Verde', label: 'Cape Verde' },
	{ value: 'Cayman Islands', label: 'Cayman Islands' },
	{ value: 'Central African Republic', label: 'Central African Republic' },
	{ value: 'Chad', label: 'Chad' },
	{ value: 'Chile', label: 'Chile' },
	{ value: 'China', label: 'China' },
	{ value: 'Christmas Island', label: 'Christmas Island' },
	{ value: 'Cocos (Keeling) Islands', label: 'Cocos (Keeling) Islands' },
	{ value: 'Colombia', label: 'Colombia' },
	{ value: 'Comoros', label: 'Comoros' },
	{ value: 'Congo', label: 'Congo' },
	{ value: 'Congo, The Democratic Republic of the', label: 'Congo, The Democratic Republic of the' },
	{ value: 'Cook Islands', label: 'Cook Islands' },
	{ value: 'Costa Rica', label: 'Costa Rica' },
	{ value: "Cote DIvoire", label: "Cote D'Ivoire" },
	{ value: 'Croatia', label: 'Croatia' },
	{ value: 'Cuba', label: 'Cuba' },
	{ value: 'Cyprus', label: 'Cyprus' },
	{ value: 'Czech Republic', label: 'Czech Republic' },
	{ value: 'Denmark', label: 'Denmark' },
	{ value: 'Djibouti', label: 'Djibouti' },
	{ value: 'Dominica', label: 'Dominica' },
	{ value: 'Dominican Republic', label: 'Dominican Republic' },
	{ value: 'Ecuador', label: 'Ecuador' },
	{ value: 'Egypt', label: 'Egypt' },
	{ value: 'El Salvador', label: 'El Salvador' },
	{ value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
	{ value: 'Eritrea', label: 'Eritrea' },
	{ value: 'Estonia', label: 'Estonia' },
	{ value: 'Ethiopia', label: 'Ethiopia' },
	{ value: 'Falkland Islands (Malvinas)', label: 'Falkland Islands (Malvinas)' },
	{ value: 'Faroe Islands', label: 'Faroe Islands' },
	{ value: 'Fiji', label: 'Fiji' },
	{ value: 'Finland', label: 'Finland' },
	{ value: 'France', label: 'France' },
	{ value: 'French Guiana', label: 'French Guiana' },
	{ value: 'French Polynesia', label: 'French Polynesia' },
	{ value: 'French Southern Territories', label: 'French Southern Territories' },
	{ value: 'Gabon', label: 'Gabon' },
	{ value: 'Gambia', label: 'Gambia' },
	{ value: 'Georgia', label: 'Georgia' },
	{ value: 'Germany', label: 'Germany' },
	{ value: 'Ghana', label: 'Ghana' },
	{ value: 'Gibraltar', label: 'Gibraltar' },
	{ value: 'Greece', label: 'Greece' },
	{ value: 'Greenland', label: 'Greenland' },
	{ value: 'Grenada', label: 'Grenada' },
	{ value: 'Guadeloupe', label: 'Guadeloupe' },
	{ value: 'Guam', label: 'Guam' },
	{ value: 'Guatemala', label: 'Guatemala' },
	{ value: 'Guernsey', label: 'Guernsey' },
	{ value: 'Guinea', label: 'Guinea' },
	{ value: 'Guinea-Bissau', label: 'Guinea-Bissau' },
	{ value: 'Guyana', label: 'Guyana' },
	{ value: 'Haiti', label: 'Haiti' },
	{ value: 'Heard Island and Mcdonald Islands', label: 'Heard Island and Mcdonald Islands' },
	{ value: 'Holy See (Vatican City State)', label: 'Holy See (Vatican City State)' },
	{ value: 'Honduras', label: 'Honduras' },
	{ value: 'Hong Kong', label: 'Hong Kong' },
	{ value: 'Hungary', label: 'Hungary' },
	{ value: 'Iceland', label: 'Iceland' },
	{ value: 'India', label: 'India' },
	{ value: 'Indonesia', label: 'Indonesia' },
	{ value: 'Iran, Islamic Republic Of', label: 'Iran, Islamic Republic Of' },
	{ value: 'Iraq', label: 'Iraq' },
	{ value: 'Ireland', label: 'Ireland' },
	{ value: 'Isle of Man', label: 'Isle of Man' },
	{ value: 'Israel', label: 'Israel' },
	{ value: 'Italy', label: 'Italy' },
	{ value: 'Jamaica', label: 'Jamaica' },
	{ value: 'Japan', label: 'Japan' },
	{ value: 'Jersey', label: 'Jersey' },
	{ value: 'Jordan', label: 'Jordan' },
	{ value: 'Kazakhstan', label: 'Kazakhstan' },
	{ value: 'Kenya', label: 'Kenya' },
	{ value: 'Kiribati', label: 'Kiribati' },
	{ value: "Korea, Democratic PeopleS Republic of", label: "Korea, Democratic People'S Republic of" },
	{ value: 'Korea, Republic of', label: 'Korea, Republic of' },
	{ value: 'Kuwait', label: 'Kuwait' },
	{ value: 'Kyrgyzstan', label: 'Kyrgyzstan' },
	{ value: "Lao PeopleS Democratic Republic", label: "Lao People'S Democratic Republic" },
	{ value: 'Latvia', label: 'Latvia' },
	{ value: 'Lebanon', label: 'Lebanon' },
	{ value: 'Lesotho', label: 'Lesotho' },
	{ value: 'Liberia', label: 'Liberia' },
	{ value: 'Libyan Arab Jamahiriya', label: 'Libyan Arab Jamahiriya' },
	{ value: 'Liechtenstein', label: 'Liechtenstein' },
	{ value: 'Lithuania', label: 'Lithuania' },
	{ value: 'Luxembourg', label: 'Luxembourg' },
	{ value: 'Macao', label: 'Macao' },
	{ value: 'Macedonia, The Former Yugoslav Republic of', label: 'Macedonia, The Former Yugoslav Republic of' },
	{ value: 'Madagascar', label: 'Madagascar' },
	{ value: 'Malawi', label: 'Malawi' },
	{ value: 'Malaysia', label: 'Malaysia' },
	{ value: 'Maldives', label: 'Maldives' },
	{ value: 'Mali', label: 'Mali' },
	{ value: 'Malta', label: 'Malta' },
	{ value: 'Marshall Islands', label: 'Marshall Islands' },
	{ value: 'Martinique', label: 'Martinique' },
	{ value: 'Mauritania', label: 'Mauritania' },
	{ value: 'Mauritius', label: 'Mauritius' },
	{ value: 'Mayotte', label: 'Mayotte' },
	{ value: 'Mexico', label: 'Mexico' },
	{ value: 'Micronesia, Federated States of', label: 'Micronesia, Federated States of' },
	{ value: 'Moldova, Republic of', label: 'Moldova, Republic of' },
	{ value: 'Monaco', label: 'Monaco' },
	{ value: 'Mongolia', label: 'Mongolia' },
	{ value: 'Montserrat', label: 'Montserrat' },
	{ value: 'Morocco', label: 'Morocco' },
	{ value: 'Mozambique', label: 'Mozambique' },
	{ value: 'Myanmar', label: 'Myanmar' },
	{ value: 'Namibia', label: 'Namibia' },
	{ value: 'Nauru', label: 'Nauru' },
	{ value: 'Nepal', label: 'Nepal' },
	{ value: 'Netherlands', label: 'Netherlands' },
	{ value: 'Netherlands Antilles', label: 'Netherlands Antilles' },
	{ value: 'New Caledonia', label: 'New Caledonia' },
	{ value: 'New Zealand', label: 'New Zealand' },
	{ value: 'Nicaragua', label: 'Nicaragua' },
	{ value: 'Niger', label: 'Niger' },
	{ value: 'Nigeria', label: 'Nigeria' },
	{ value: 'Niue', label: 'Niue' },
	{ value: 'Norfolk Island', label: 'Norfolk Island' },
	{ value: 'Northern Mariana Islands', label: 'Northern Mariana Islands' },
	{ value: 'Norway', label: 'Norway' },
	{ value: 'Oman', label: 'Oman' },
	{ value: 'Pakistan', label: 'Pakistan' },
	{ value: 'Palau', label: 'Palau' },
	{ value: 'Palestinian Territory, Occupied', label: 'Palestinian Territory, Occupied' },
	{ value: 'Panama', label: 'Panama' },
	{ value: 'Papua New Guinea', label: 'Papua New Guinea' },
	{ value: 'Paraguay', label: 'Paraguay' },
	{ value: 'Peru', label: 'Peru' },
	{ value: 'Philippines', label: 'Philippines' },
	{ value: 'Pitcairn', label: 'Pitcairn' },
	{ value: 'Poland', label: 'Poland' },
	{ value: 'Portugal', label: 'Portugal' },
	{ value: 'Puerto Rico', label: 'Puerto Rico' },
	{ value: 'Qatar', label: 'Qatar' },
	{ value: 'Reunion', label: 'Reunion' },
	{ value: 'Romania', label: 'Romania' },
	{ value: 'Russian Federation', label: 'Russian Federation' },
	{ value: 'RWANDA', label: 'RWANDA' },
	{ value: 'Saint Helena', label: 'Saint Helena' },
	{ value: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
	{ value: 'Saint Lucia', label: 'Saint Lucia' },
	{ value: 'Saint Pierre and Miquelon', label: 'Saint Pierre and Miquelon' },
	{ value: 'Saint Vincent and the Grenadines', label: 'Saint Vincent and the Grenadines' },
	{ value: 'Samoa', label: 'Samoa' },
	{ value: 'San Marino', label: 'San Marino' },
	{ value: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },
	{ value: 'Saudi Arabia', label: 'Saudi Arabia' },
	{ value: 'Senegal', label: 'Senegal' },
	{ value: 'Serbia and Montenegro', label: 'Serbia and Montenegro' },
	{ value: 'Seychelles', label: 'Seychelles' },
	{ value: 'Sierra Leone', label: 'Sierra Leone' },
	{ value: 'Singapore', label: 'Singapore' },
	{ value: 'Slovakia', label: 'Slovakia' },
	{ value: 'Slovenia', label: 'Slovenia' },
	{ value: 'Solomon Islands', label: 'Solomon Islands' },
	{ value: 'Somalia', label: 'Somalia' },
	{ value: 'South Africa', label: 'South Africa' },
	{ value: 'South Georgia and the South Sandwich Islands', label: 'South Georgia and the South Sandwich Islands' },
	{ value: 'Spain', label: 'Spain' },
	{ value: 'Sri Lanka', label: 'Sri Lanka' },
	{ value: 'Sudan', label: 'Sudan' },
	{ value: 'Suriname', label: 'Suriname' },
	{ value: 'Svalbard and Jan Mayen', label: 'Svalbard and Jan Mayen' },
	{ value: 'Swaziland', label: 'Swaziland' },
	{ value: 'Sweden', label: 'Sweden' },
	{ value: 'Switzerland', label: 'Switzerland' },
	{ value: 'Syrian Arab Republic', label: 'Syrian Arab Republic' },
	{ value: 'Taiwan, Province of China', label: 'Taiwan, Province of China' },
	{ value: 'Tajikistan', label: 'Tajikistan' },
	{ value: 'Tanzania, United Republic of', label: 'Tanzania' },
	{ value: 'Thailand', label: 'Thailand' },
	{ value: 'Timor-Leste', label: 'Timor-Leste' },
	{ value: 'Togo', label: 'Togo' },
	{ value: 'Tokelau', label: 'Tokelau' },
	{ value: 'Tonga', label: 'Tonga' },
	{ value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
	{ value: 'Tunisia', label: 'Tunisia' },
	{ value: 'Turkey', label: 'Turkey' },
	{ value: 'Turkmenistan', label: 'Turkmenistan' },
	{ value: 'Turks and Caicos Islands', label: 'Turks and Caicos Islands' },
	{ value: 'Tuvalu', label: 'Tuvalu' },
	{ value: 'Uganda', label: 'Uganda' },
	{ value: 'Ukraine', label: 'Ukraine' },
	{ value: 'United Arab Emirates', label: 'United Arab Emirates' },
	{ value: 'United Kingdom', label: 'United Kingdom' },
	{ value: 'United States', label: 'United States' },
	{ value: 'United States Minor Outlying Islands', label: 'United States Minor Outlying Islands' },
	{ value: 'Uruguay', label: 'Uruguay' },
	{ value: 'Uzbekistan', label: 'Uzbekistan' },
	{ value: 'Vanuatu', label: 'Vanuatu' },
	{ value: 'Venezuela', label: 'Venezuela' },
	{ value: 'Viet Nam', label: 'Viet Nam' },
	{ value: 'Virgin Islands, British', label: 'Virgin Islands, British' },
	{ value: 'Virgin Islands, U.S.', label: 'Virgin Islands, U.S.' },
	{ value: 'Wallis and Futuna', label: 'Wallis and Futuna' },
	{ value: 'Western Sahara', label: 'Western Sahara' },
	{ value: 'Yemen', label: 'Yemen' },
	{ value: 'Zambia', label: 'Zambia' },
	{ value: 'Zimbabwe', label: 'Zimbabwe' }
];
const categoryOption = [
	{ label: 'Created', value: 'created' },
	{ label: 'Pickup', value: 'Pickup' },
	{ label: 'Transfer', value: 'Transfer' },
	{ label: 'Customs', value: 'Customs' },
	{ label: 'Delivered', value: 'Delivered' },
	{ label: 'Received', value: 'Received' },
	{ label: 'Despatched', value: 'Despatched' },
	{ label: 'Sorting Facility', value: 'Sorting Facility' },
	{ label: 'Onboard to Delivery Address', value: 'Onboard to Delivery Address' },
	{ label: 'Held at Customs', value: 'Held at Customs' },
	{ label: 'Custom Released', value: 'Custom Released' },
	{ label: 'Delivery Missed', value: 'Delivery Missed' },
	{ label: 'Delivery Exception', value: 'Delivery Exception' },
	{ label: 'Intransit', value: 'Intransit' },
	{ label: 'Custom Cleared', value: 'Custom Cleared' },
	{ label: 'Airport Transfer', value: 'Airport Transfer' },
	{ label: 'Destination Airport', value: 'Destination Airport' },
	{ label: 'Onforwarded', value: 'Onforwarded' },
	{ label: 'Arrived', value: 'Arrived' },
	{ label: 'Departed', value: 'Departed' },
	{ label: 'Custom Inspection', value: 'Custom Inspection' },
	{ label: 'Collect', value: 'Collect' },
	{ label: 'Collected', value: 'Collected' },
	{ label: 'Accepted', value: 'Accepted' },
	{ label: 'Warehouse Received', value: 'Warehouse Received' },
	{ label: 'Outbound Staged', value: 'Outbound Staged' },
	{ label: 'Biosecurity Inspection', value: 'Biosecurity Inspection' },
	{ label: 'Cancelled', value: 'Cancelled' },
	{ label: 'Withdrawn', value: 'Withdrawn' },
	{ label: 'Airline Transfer', value: 'Airline Transfer' },
	{ label: 'Damaged', value: 'Damaged' },
	{ label: 'Lost', value: 'Lost' },
	{ label: 'Missing', value: 'Missing' },
	{ label: 'Abandoned', value: 'Abandoned' },
	{ label: 'Unclaimed', value: 'Unclaimed' },
	{ label: 'Biosecurity Destruction', value: 'Biosecurity Destruction' },
	{ label: 'Destruction', value: 'Destruction' },
	{ label: 'Partial Disposal', value: 'Partial Disposal' },
	{ label: 'Biosecurity Control', value: 'Biosecurity Control' },
	{ label: 'Re-Export', value: 'Re-Export' },
	{ label: 'Tran-Shipment', value: 'Tran-Shipment' },
];

const packageTypeOption = [
	{ value: 'box', label: 'Box' },
	{ value: 'satchel', label: 'Satchel' },
	{ value: 'bag', label: 'Bag' },
	{ value: 'other', label: 'Other' }
];
const modeOfTransportOption = [{ value: 'Air', label: 'Air' },
{ value: 'Sea', label: 'Sea' },
{ value: 'Road', label: 'Road' },
{ value: 'Rail', label: 'Rail' }]

export default class CreateShipment extends Component {
	input = React.createRef();
	state = {
		deviceADcode: '',
		location: '',
		nfcADcode: '',
		nfcserialno: '',
		quantity: '',
		refno: '',
		trackingstatus: '',
		type: '',
		userId: '',
		phoneNumber: '',
		comment: '',
		origin: '',
		destination: '',
		carrierName: '',
		receiverName: '',
		receiverEmail: '',
		receiverBusinessName: '',
		deliveryAddress: '',
		document: null,
		loading: false,
		addressLoading: false,
		isScanning: false,
		scanner: '',
		modal: true,
		scanType: '',
		barCodeScan: '',
		packageType: '',
		modeOfTransport: '',
		confirmOriginCountry: '',
		confirmDestinationCountry: '',
		weight: '',
		dimension: {
			height: '',
			width: '',
			length: '',
		},
		addressModal: false,
		addressBook: '',
		addressButton: false,
		pickup: '',
		data: [],
		customerRefNo: '',
		packageCurrency: 'USD',
		packageValue: '',
		receiverPhoneNumber: '',
		showConsigne: false,
		consigneeNumber: '',
		selectShipment: false,
		origin_country: '',
		destination_country: '',
		shipmentDescription: '',
		emailAddress1: '',
		phoneNumber1: '',
		shipperName1: '',
		shipperEmail1: '',
		shipperPhone1: '',
		shipperAddress1: '',
		filesData: [],
		hideAdCodes: false,
		adCodes: '',
		tags: [],
		errorTags: [],
		editTags: [],
		editActiveValue: '',
		customErr: '',
		isValidPhoneNo: true,
		receiverPhoneNumberIsValidPhoneNo: true,
		phoneNumber1IsValidPhoneNo: true,
		shipperPhone1IsValidPhoneNo: true
	}

	async componentDidMount() {
		callActivityApi({ 'pageName': 'Create Shipment', 'description': 'User has visited Create shipment Screen' });
		const historyPush = this.props.history.push
		phoneNoVerifyCheck(historyPush)
		const data = await localStorage.getItem('auth');
		const { id, ADCode, address } = JSON.parse(data);
		this.setState({ userId: id, deviceADcode: ADCode, pickup: address });

		getLocation().then((location) => this.setState({ location }));
		getAddress({ userId: id })
			.then(Response => {
				this.setState({ data: Response.data.data })
			})
			.catch(err => console.log(err))
	}

	submitHandler = async (values) => {


		try {
			// Validate origin country
			if (values.origin_country !== values.confirmOriginCountry &&
				(values?.modeOfTransport === "Air" || values?.modeOfTransport === "Sea")) {
				toast.error("origin and origin country not matched, try again!");
				return
			}

			if (values.destination_country !== values.confirmDestinationCountry &&
				(values?.modeOfTransport === "Air" || values?.modeOfTransport === "Sea")) {
				toast.error("Destination and destination country not matched, try again!");
				return
			}





			// Proceed with shipment creation if validation passes
			let symbol = currenySymbol.find((o) => o.iso_code === values.packageCurrency)?.symbol;
			this.setState({ loading: true });

			const response = await createShipment({
				...values,
				shipmentType: 'International',
				currencySymbol: symbol,
				deviceADcode: this.state.deviceADcode,
				userId: this.state.userId,
				invoice: this.state.document,
				location: this.state.location,
				filesData: this.state.filesData,
			});

			toast.success(response.data.message, {
				position: toast.POSITION.TOP_RIGHT,
			});

			if (+values.quantity >= 1) {
				this.props.history.push('/admin/adhoc', { refno: values.refno, quantity: values.quantity });
			} else {
				this.props.history.push('/admin/index');
			}
		} catch (err) {
			let message = err.message;
			if (err.response && err.response.data.message) {
				message = err.response.data.message;
			}
			toast.error(message, {
				position: toast.POSITION.TOP_RIGHT,
			});
			this.setState({ loading: false });
		}
	};


	fileExists = (arr, name) => {
		return arr.some(function (el) {
			return el.imageName === name;
		});
	}


	onSelectFile = (key) => e => {
		var files = this.state.filesData;

		if (e.target.files && e.target.files.length > 0) {
			for (var k = 0; k < e.target.files.length; k++) {
				if (e.target.files[k].size > 524000) {
					toast.error('File size must be 500 Kb', {
						position: toast.POSITION.TOP_RIGHT
					});
				} else if (this.fileExists(files, e.target.files[k].name)) {
					toast.error('File already exist', {
						position: toast.POSITION.TOP_RIGHT
					});
				} else {
					console.log(this.fileExists(files, e.target.files[k].name));
					console.log(files);
					console.log(e.target.files[k].name);
					files.push({
						'imageName': e.target.files[k].name,
						'imageDescription': '',
						'type': e.target.files[k].type,
						'filesInfo': e.target.files[k]
					});
				}

			}
			this.setState({ filesData: files });
		}
		// const { name, type, size } = e.target.files[0];
		// this.setState({ document: { name, type, size } });
		// if (e.target.files && e.target.files.length > 0) {
		// 	const reader = new FileReader();
		// 	reader.addEventListener("load", () =>
		// 		this.setState({ documentImage: reader.result }),
		// 	);
		// 	reader.readAsDataURL(e.target.files[0]);
		// }
	};

	handleInputChange = (index, e, name, setFieldValue) => {
		// console.log(index, e, name);
		var value;
		value = e.target.value;

		const list = [...this.state.filesData];
		list[index][name] = value;
		this.setState({ filesData: list });
		setFieldValue('filesData', list);
	};

	imageRemoveHandler = (index, setFieldValue) => {
		const list = [...this.state.filesData];
		list.splice(index, 1);
		this.setState({ filesData: list });
		setFieldValue('filesData', list);
		setFieldValue(`x.${index}.imageName`, '');
		setFieldValue(`x.${index}.imageDescription`, '');
	};

	addressSaveHandler = (e) => {
		this.setState({ addressBook: e, deliveryAddress: '', addressLoading: true })
		addAddress({ userId: this.state.userId, address: e })
			.then(response => {
				getAddress({ userId: this.state.userId })
					.then(Response => {
						this.setState({ data: Response.data.data, addressButton: false, addressLoading: false })
					})
					.catch(err => console.log(err))
			})
	}

	addressBookHandler = () => {
		this.setState({ addressModal: !this.state.addressModal });
	}

	searchConsignee = (setFieldValue) => {
		if (this.state.consigneeNumber) {
			getConsigneeDetails({
				consigneeId: this.state.consigneeNumber
			}).then(Response => {
				setFieldValue('receiverName', Response.data.data.firstname + ' ' + Response.data.data.lastname);
				setFieldValue('receiverEmail', Response.data.data.emailaddress);
				setFieldValue('receiverPhoneNumber', Response.data.data.mobilephone);

				//console.log(Response.data.data);
			})
				.catch(err => {
					let message = err.message;
					if (err.response && err.response.data.message) {
						message = err.response.data.message;
					}
					toast.error(message, {
						position: toast.POSITION.TOP_RIGHT
					});
					this.setState({ loading: false });
				})
		} else {
			toast.error('Consignee Number is required', {
				position: toast.POSITION.TOP_RIGHT
			});
		}
	}

	changeCheckbox = (setFieldValue, hideAdCodes) => {
		setFieldValue("hideAdCodes", !hideAdCodes);
		if (hideAdCodes == true) {
			setFieldValue('adCodes', '');
			this.setState({
				tags: [], errorTags: [],
				editTags: [],
				editActiveValue: '',
				customErr: '',
			})
		}
	};

	getLastShipmentDetail = (setFieldValue) => {
		getLastShipments({
			deviceADcode: this.state.deviceADcode
		}).then(Response => {
			// console.log(Response.data.data);
			setFieldValue('quantity', Response.data.data.quantity);
			setFieldValue('trackingstatus', Response.data.data.trackingstatus);
			setFieldValue('comment', Response.data.data.comment);
			setFieldValue('origin', Response.data.data.origin);
			setFieldValue('destination', Response.data.data.destination);
			setFieldValue('carrierName', Response.data.data.carrierName);
			setFieldValue('receiverName', Response.data.data.receiverName);
			setFieldValue('receiverEmail', Response.data.data.receiverEmail);
			setFieldValue('receiverBusinessName', Response.data.data.receiverBusinessName);
			setFieldValue('deliveryAddress', Response.data.data.deliveryAddress);
			setFieldValue('packageType', Response.data.data.packageType);
			setFieldValue('modeOfTransport', Response?.data?.data?.modeOfTransport)
			setFieldValue('weight', Response.data.data.weight);
			setFieldValue('height', Response.data.data.height);
			setFieldValue('width', Response.data.data.width);
			setFieldValue('length', Response.data.data.length);
			setFieldValue('pickup', Response.data.data.pickup);
			setFieldValue('customerRefNo', Response.data.data.customerRefNo);
			setFieldValue('packageCurrency', Response.data.data.packageCurrency);
			setFieldValue('packageValue', Response.data.data.packageValue);
			setFieldValue('receiverPhoneNumber', Response.data.data.receiverPhone);

		})
			.catch(err => {
				let message = err.message;
				if (err.response && err.response.data.message) {
					message = err.response.data.message;
				}
				toast.error('Last Shipment is not found!', {
					position: toast.POSITION.TOP_RIGHT
				});
				this.setState({ loading: false });
			})
	}

	shipToSelf = async (setFieldValue) => {
		const data = await localStorage.getItem('auth');
		const { firstName, lastName, email, businesname, phoneNumber } = JSON.parse(data);
		setFieldValue('receiverName', firstName + ' ' + lastName);
		setFieldValue('receiverEmail', email);
		setFieldValue('receiverPhoneNumber', phoneNumber);
		setFieldValue('receiverBusinessName', businesname);
	};

	changeDeliveryHandler = (e) => {
		const data = this.state.data
		const value = e.target.value;
		if (!value) {
			this.setState({ addressButton: false });
			return;
		}
		if (data.find(p => p.address === value)) {
			this.setState({ addressButton: false });
			return;
		}
		this.setState({ addressButton: true });
	}

	// handle tags code
	handleTags = (tags, setFieldValue) => {
		this.setState({ loading: true });
		//call api to check here info
		let data = {
			adCodes: tags
		};
		validateUserAdCodes(data)
			.then(Response => {
				//console.log(Response);
				this.setState({ loading: false, tags: tags, customErr: '' });
				setFieldValue('adCodes', tags.toString());
			}).catch(err => {
				this.setState({ loading: false });
				let message = err.message;
				if (err.response && err.response.data.message) {
					message = err.response.data.message;
				}
				this.setState({ errorTags: (err.response.data.data === null ? [] : err.response.data.data), tags: tags, customErr: message });
				setFieldValue('adCodes', tags.toString());
				toast.error(message, {
					position: toast.POSITION.TOP_RIGHT
				});
			});

	};
	checkEdit = (key) => {
		let editTags = [];
		editTags.push(this.state.tags[key]);
		this.setState({ editTags, editActiveValue: this.state.tags[key] });
	};

	handleEdit = (value, key) => {
		// console.log(value);
		let tags = this.state.tags;
		tags[key] = value;
		this.setState({ loading: true, tags, editActiveValue: '', editTags: [] });
		//call api to check here info
		let data = {
			adCodes: tags
		};
		validateUserAdCodes(data)
			.then(Response => {
				//console.log(Response);
				this.setState({ loading: false, tags: tags, customErr: '' });
				//setFieldValue('refno', tags.toString());
			}).catch(err => {
				this.setState({ loading: false });
				let message = err.message;
				if (err.response && err.response.data.message) {
					message = err.response.data.message;
				}
				this.setState({ errorTags: (err.response.data.data === null ? [] : err.response.data.data), tags: tags, customErr: message });
				//setFieldValue('refno', tags.toString());
				toast.error(message, {
					position: toast.POSITION.TOP_RIGHT
				});
			});
	};

	pasteSplit = (data) => {
		const separators = [',', ';', '\\(', '\\)', '\\*', '/', ':', '\\?', '\n', '\r', 'g'];
		return data.split(new RegExp(separators.join('|'))).map(d => d.trim());
	};
	renderTags = (props) => {
		// console.log(this.state.errorTags);
		let { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;
		let classvar = { ...other };
		if (this.state.errorTags.includes(getTagDisplayValue(tag))) {
			classvar.className = "react-tagsinput-tag error";
		}
		if (this.state.editTags.includes(getTagDisplayValue(tag))) {
			return (
				<Input
					name={'edit' + key}
					onChange={(tags) => {
						this.setState({ editActiveValue: tags.target.value });
					}}
					onKeyPress={event => {
						//console.log(event.key);
						if (event.key === 'Enter') {
							this.handleEdit(event.target.value, key);
						}
					}}
					className={'editTag'}
					value={this.state.editActiveValue}
				/>
			)
		} else {
			return (
				<span key={key} {...classvar} >
					<span onClick={() => this.checkEdit(key)}> {getTagDisplayValue(tag)}</span>
					{!disabled &&
						<a className={classNameRemove} onClick={(e) => onRemove(key)} />
					}
				</span>
			)
		}
	};



	handlePhoneNumberChange = (isEdited, phoneNumber, selectedCountry, setFieldValue, fieldName) => {
		const countryDialCode = `+${selectedCountry.dialCode}`;
		// Remove the country code if the user manually entered it
		if (phoneNumber.startsWith(countryDialCode)) {
			phoneNumber = phoneNumber.slice(countryDialCode.length);
		}
		const isValid = isValidNumber(phoneNumber, [selectedCountry.iso2.toUpperCase()]);
		this.setState({ [`${fieldName}IsValidPhoneNo`]: isValid });

		const number = `${countryDialCode}${phoneNumber}`;
		setFieldValue(fieldName, number);
	};

	fetchOriginCountry = async (option, name, values, setFieldValue) => {

		setFieldValue(name, option.value)
		try {
			if (values.modeOfTransport === 'Air') {
				const response = await getCountryData(option.iso)
				if (response.data[0]?.name?.common) {

					setFieldValue(name === 'origin' ? 'origin_country' : 'destination_country', response.data[0]?.name?.common);
					setFieldValue(name === 'origin' ? 'confirmOriginCountry' : 'confirmDestinationCountry', response.data[0]?.name?.common);
				}
			} else if (values.modeOfTransport === 'Sea') {
				setFieldValue(name === 'origin' ? 'origin_country' : 'destination_country', option.country);
				setFieldValue(name === 'origin' ? 'confirmOriginCountry' : 'confirmDestinationCountry', option.country);
			}
		} catch (error) {
			// console.log(error)
		}

	}




	render() {
		const { deviceADcode } = this.state;
		if (!this.state.selectShipment) {
			return (
				<SelectShipmentModal
					scan={(value) => this.setState({ isScanning: false, selectShipment: true, shipmentType: value.shipmentType })}
					close={this.props.history}
				/>
			)
		}

		if (this.state.selectShipment && !this.state.isScanning) {
			return (
				<Modals scan={(value) => this.setState({ nfcADcode: value.nfcADcode, type: value.type, phoneNumber: value.phoneNumber, nfcserialno: value.nfcserialno, isScanning: true, })
				} scanType={(scanType) => this.setState({ scanType, })
				} close={this.props.history} />
			)
		}



		return (
			<div>
				< Formik
					isInitialValid={false}
					enableReinitialize
					initialValues={{
						origin: this.state.origin,
						destination: this.state.destination,
						carrierName: this.state.carrierName,
						deliveryAddress: this.state.deliveryAddress,
						invoice: this.state.document,
						type: this.state.type,
						phoneNumber: this.state.phoneNumber,
						nfcADcode: this.state.nfcADcode,
						trackingstatus: this.state.trackingstatus,
						receiverName: this.state.receiverName,
						receiverBusinessName: this.state.receiverBusinessName,
						receiverEmail: this.state.receiverEmail,
						comment: this.state.comment,
						refno: this.state.refno,
						quantity: this.state.quantity,
						nfcserialno: this.state.nfcserialno,
						packageType: this.state.packageType,
						modeOfTransport: this.state.modeOfTransport,
						weight: this.state.weight,
						height: this.state.dimension.height,
						width: this.state.dimension.width,
						length: this.state.dimension.length,
						pickup: this.state.pickup,
						customerRefNo: this.state.customerRefNo,
						packageCurrency: this.state.packageCurrency,
						packageValue: this.state.packageValue,
						receiverPhoneNumber: this.state.receiverPhoneNumber,
						origin_country: this.state.origin_country,
						destination_country: this.state.destination_country,
						shipmentDescription: this.state.shipmentDescription,
						emailAddress1: this.state.emailAddress1,
						phoneNumber1: this.state.phoneNumber1,
						shipperName1: this.state.shipperName1,
						shipperEmail1: this.state.shipperEmail1,
						shipperPhone1: this.state.shipperPhone1,
						shipperAddress1: this.state.shipperAddress1,
						adCodes: this.state.adCodes,
						hideAdCodes: this.state.hideAdCodes

					}}
					validationSchema={Schema}
					onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
						resetForm({ values: '' })
						this.setErrors = setErrors;
						this.submitHandler(values);
					}}>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleSubmit,
						handleBlur,
						setFieldValue,
					}) => (
						<>
							<AddressBook data={this.state.data} isOpen={this.state.addressModal} userId={this.state.userId} isClose={this.addressBookHandler} address={(deliveryAddress) => setFieldValue('deliveryAddress', deliveryAddress)} />
							<div className="mt-2 style">
								<Card>
									<CardHeader className="c-header">Create International Shipment</CardHeader>
									<div className="mt-3 col-12">
										<div className="row m-0">
											<div className="col-lg-2 col-xs-12 col-sm-12 mb-3 mb-sm-3">
												<button class="btn btn-primary" onClick={() => this.setState({ showConsigne: true })} >Fetch Consignee</button>
											</div>
											<div className="col-lg-2 col-xs-12 col-sm-12 mb-3 mb-sm-3">
												<button class="btn btn-primary" onClick={() => this.shipToSelf(setFieldValue)} >Ship to Self</button>
											</div>
											<div className="col-lg-3 col-xs-12 col-sm-12 ">
												<button class="btn btn-primary " onClick={() => this.getLastShipmentDetail(setFieldValue)}>Add Last Shipment</button>
											</div>
										</div>
									</div>
									<CardBody>
										{this.state.showConsigne ?
											<div className="">
												<div className="col-lg-6 col-sm-12">
													<div className="input-group mb-3">
														<Input type="text"
															name="consigneeNumber"
															onChange={(text) => this.setState({ consigneeNumber: trim(text.target.value) })}
															value={this.state.consigneeNumber}
															placeholder="Enter Consignee Number here" />
														<div className="input-group-append">
															<button className="form-control form-style-w3layout-btn" onClick={() => this.searchConsignee(setFieldValue)}>
																<span className="fa fa-search"></span>
															</button>
														</div>
													</div>
												</div></div> : null}

										<div className="col-12">
											<FormGroup>
												<Label>AD Code</Label>
												<Input type="text"
													readOnly
													invalid={errors.nfcADcode && touched.nfcADcode}
													name="nfcADcode" onChange={handleChange} onBlur={handleBlur} value={values.nfcADcode}
													placeholder="Enter AD code" />
												<FormFeedback>
													{errors.nfcADcode && touched.nfcADcode && errors.nfcADcode}
												</FormFeedback>
											</FormGroup>
										</div>

										<div className="col-12">
											<FormGroup>
												<Label>Tracking No</Label>
												<Input type="text"
													readOnly
													invalid={errors.refno && touched.refno}
													name="refno" onChange={handleChange('refno')} onBlur={handleBlur('refno')}
													value={values.refno} placeholder="" />
												<div className="pb-2 pt-2">
													<button className="btn-generate"
														onClick={() => setFieldValue('refno', 'G' + tsid(10))}>Generate
													</button>
												</div>
												<FormFeedback>
													{errors.refno && touched.refno && errors.refno}
												</FormFeedback>
											</FormGroup>
										</div>

										<div className="col-12">
											<FormGroup>
												<Label>Mode of Transport (MOT)</Label>
												<Select
													name="modeOfTransport"
													onChange={(option) => setFieldValue('modeOfTransport', option.value)}
													onBlur={handleBlur}
													className="is-invalid"
													value={modeOfTransportOption.filter(option => values.modeOfTransport === option.value)}
													options={modeOfTransportOption}
												/>
												<FormFeedback>
													{errors.modeOfTransport && touched.modeOfTransport && errors.modeOfTransport}
												</FormFeedback>
											</FormGroup>
										</div>

										<div className="col-12">
											<FormGroup>
												<Label>Quantity of box items</Label>
												<Input type="text"
													invalid={errors.quantity && touched.quantity}
													name="quantity" onChange={handleChange} onBlur={handleBlur} value={values.quantity}
													placeholder="Enter quantity of box items" />
												<FormFeedback>
													{errors.quantity && touched.quantity && errors.quantity}
												</FormFeedback>
											</FormGroup>
										</div>

										<div className="col-12">
											<FormGroup>
												<Label> Status</Label>
												<Select
													name="trackingstatus"
													onChange={(option) => setFieldValue('trackingstatus', option.value)}
													onBlur={handleBlur}
													className="is-invalid"
													value={categoryOption.filter(option => values.trackingstatus === option.value)}
													options={categoryOption}
												/>
												<FormFeedback>
													{errors.trackingstatus && touched.trackingstatus && errors.trackingstatus}
												</FormFeedback>
											</FormGroup>
										</div>

										<div className="col-12">
											<FormGroup>
												<Label>Package Type</Label>
												<Select
													name="packageType"
													onChange={(option) => setFieldValue('packageType', option.value)}
													onBlur={handleBlur}
													className="is-invalid"
													value={packageTypeOption.filter(option => values.packageType === option.value)}
													options={packageTypeOption}
												/>
												<FormFeedback>
													{errors.packageType && touched.packageType && errors.packageType}
												</FormFeedback>
											</FormGroup>
										</div>

										<div className="col-12">
											<FormGroup>
												<Label>Total Weight (kg)

												</Label>
												<Input type="text"
													invalid={errors.weight && touched.weight}
													name="weight" onChange={handleChange} onBlur={handleBlur} value={values.weight}
													placeholder="Enter weight" />
												<FormFeedback>
													{errors.weight && touched.weight && errors.weight}
												</FormFeedback>
											</FormGroup>
										</div>

										<div className="col-12">
											<FormGroup>
												<Label>Dimension (cm)</Label>
												<div className="row">
													<div className="col-lg-4 col-xs-12 col-sm-12 ">
														<Input type="text"
															className="mt-sm-3 mt-lg-0"
															invalid={errors.length && touched.length}
															name="length" onChange={handleChange} onBlur={handleBlur} value={values.length}
															placeholder="Enter Length of Dimension" />
													</div>
													<div className="col-lg-4 col-xs-12 col-sm-12 mb-3 mb-sm-3">
														<Input type="text"
															className="mt-sm-3 mt-lg-0"
															invalid={errors.width && touched.width}
															name="width" onChange={handleChange} onBlur={handleBlur} value={values.width}
															placeholder="Enter Width of Dimension" />
													</div>
													<div className="col-lg-4 col-xs-12 col-sm-12 mb-3 mb-sm-3">
														<Input type="text"
															invalid={errors.height && touched.height}
															name="height" onChange={handleChange} onBlur={handleBlur} value={values.height}
															placeholder="Enter Height of Dimension" />
													</div>

												</div>

												<div className='row' >
													{values.weight &&
														<div className="col-lg-4 col-xs-12 col-sm-12">
															<span className='custom-span-cbm' >Average  weight per item =
																{" "}{averageWeight(Number(values.quantity), Number(values.weight))} kg
															</span>
														</div>
													}
													{values.modeOfTransport && values.length && values.width && values.height && values.quantity &&
														<div className="col-lg-4 col-xs-12 col-sm-12">
															<span className='custom-span-cbm' >Chargeable/Volumetric Weight = {calculateCVW(values.modeOfTransport, Number(values.length), Number(values.width), Number(values.height), Number(values.quantity), Number(values.weight))} kg</span>

														</div>}

													{values.length && values.width && values.height && values.quantity &&
														<div className="col-lg-4 col-xs-12 col-sm-12">
															<span className='custom-span-cbm'>CBM = {calculateCBM(Number(values.length), Number(values.width), Number(values.height), Number(values.quantity))} </span>
														</div>}
												</div>

											</FormGroup>
										</div>

										<div className="col-12">
											<FormGroup>
												<Label>Customer ref/order no.</Label>
												<Input type="text"
													invalid={errors.customerRefNo && touched.customerRefNo}
													name="customerRefNo" onChange={handleChange} onBlur={handleBlur} value={values.customerRefNo}
													placeholder="Enter Customer ref/order no." />
												<FormFeedback>
													{errors.customerRefNo && touched.customerRefNo && errors.customerRefNo}
												</FormFeedback>
											</FormGroup>
										</div>

										<div className="col-12">
											<FormGroup>
												<Label>Package Value</Label>
												<div className="row">
													<div className="col-lg-3 col-xs-12 col-sm-12 mb-3 mb-sm-3">
														<Select
															name="packageCurrency"
															onChange={(option) => setFieldValue('packageCurrency', option.value)}
															onBlur={handleBlur}
															className="is-invalid"
															value={currencyList.filter(option => values.packageCurrency === option.value)}
															options={currencyList}
														/>
														<FormFeedback>
															{errors.packageCurrency && touched.packageCurrency && errors.packageCurrency}
														</FormFeedback>
													</div>
													<div className="col-lg-9 col-xs-12 col-sm-12 mb-3 mb-sm-3">
														<Input type="number"
															invalid={errors.packageValue && touched.packageValue}
															name="packageValue" onChange={handleChange} onBlur={handleBlur} value={values.packageValue}
															placeholder="Enter Package Value" />
													</div>
												</div>
												<FormFeedback>
													{errors.packageValue && touched.packageValue && errors.packageValue}
												</FormFeedback>
											</FormGroup>
										</div>

										{/* Optional Shipper Details*/}
										<div className="col-12">
											<FormGroup>
												<Label >Shipper's Name (optional)</Label>
												<Input type="text"
													invalid={errors.shipperName1 && touched.shipperName1}
													name="shipperName1" onChange={handleChange} onBlur={handleBlur} value={values.shipperName1} placeholder="Enter Shipper Name" />
												<FormFeedback>
													{errors.shipperName1 && touched.shipperName1 && errors.shipperName1}
												</FormFeedback>
											</FormGroup>
										</div>

										<div className="col-12">
											<FormGroup>
												<Label >Shipper's Email (optional)</Label>
												<Input type="text"
													invalid={errors.shipperEmail1 && touched.shipperEmail1}
													name="shipperEmail1" onChange={handleChange} onBlur={handleBlur} value={values.shipperEmail1} placeholder="Enter Shipper Email" />
												<FormFeedback>
													{errors.shipperEmail1 && touched.shipperEmail1 && errors.shipperEmail1}
												</FormFeedback>
											</FormGroup>
										</div>

										<div className="col-12">
											<FormGroup>
												<Label >Shipper's Phone (optional)</Label>
												<div class="input-group mb-3">
													<PhoneInput
														containerClassName="intl-tel-input"
														inputClassName="form-control"
														onPhoneNumberChange={(isEdited, phoneNumber, selectedCountry) => this.handlePhoneNumberChange(isEdited, phoneNumber, selectedCountry, setFieldValue, 'shipperPhone1')}
													/>
													<h5 className='error-display'>
														{!errors.shipperPhone1 && !touched.shipperPhone1 && !this.state.shipperPhone1IsValidPhoneNo && "Please enter a valid phone number"}
														{errors.shipperPhone1 && touched.shipperPhone1 && errors.shipperPhone1}
													</h5>
												</div>

											</FormGroup>
										</div>

										<div className="col-12">
											<FormGroup>
												<Label >Shipper's Address (optional)</Label>
												<Input type="text"
													invalid={errors.shipperAddress1 && touched.shipperAddress1}
													name="shipperAddress1" onChange={handleChange} onBlur={handleBlur} value={values.shipperAddress1} placeholder="Enter Shipper Address" />
												<FormFeedback>
													{errors.shipperAddress1 && touched.shipperAddress1 && errors.shipperAddress1}
												</FormFeedback>
											</FormGroup>
										</div>

										<div className="col-12">
											<FormGroup>
												<Label >Receiver Name</Label>
												<Input type="text"
													invalid={errors.receiverName && touched.receiverName}
													name="receiverName" onChange={handleChange} onBlur={handleBlur} value={values.receiverName} placeholder="Enter Receiver Name" />
												<FormFeedback>
													{errors.receiverName && touched.receiverName && errors.receiverName}
												</FormFeedback>
											</FormGroup>
										</div>




										{values.nfcADcode === deviceADcode &&
											<>
												<div className="col-12">
													<FormGroup>
														<Label >Receiver Business Name</Label>
														<Input type="text"
															invalid={errors.receiverBusinessName && touched.receiverBusinessName}
															name="receiverBusinessName" onChange={handleChange} onBlur={handleBlur} value={values.receiverBusinessName} placeholder="Enter Receiver Business Name" />
														<FormFeedback>
															{errors.receiverBusinessName && touched.receiverBusinessName && errors.receiverBusinessName}
														</FormFeedback>
													</FormGroup>
												</div>

												<div className="col-12">
													<FormGroup>
														<Label >Receiver Email</Label>
														<Input type="text"
															invalid={errors.receiverEmail && touched.receiverEmail}
															name="receiverEmail" onChange={handleChange} onBlur={handleBlur} value={values.receiverEmail} placeholder="Enter Receiver Email" />
														<FormFeedback>
															{errors.receiverEmail && touched.receiverEmail && errors.receiverEmail}
														</FormFeedback>
													</FormGroup>
												</div>
											</>}

										<div className="col-12">
											<FormGroup>
												<Label>Receiver Phone Number</Label>
												<div class="input-group mb-3">
													<PhoneInput
														containerClassName="intl-tel-input"
														inputClassName="form-control"
														onPhoneNumberChange={(isEdited, phoneNumber, selectedCountry) => this.handlePhoneNumberChange(isEdited, phoneNumber, selectedCountry, setFieldValue, 'receiverPhoneNumber')}
													/>
													<h5 className='error-display'>
														{!errors.receiverPhoneNumber && !touched.receiverPhoneNumber && !this.state.receiverPhoneNumberIsValidPhoneNo && "Please enter a valid phone number"}
														{errors.receiverPhoneNumber && touched.receiverPhoneNumber && errors.receiverPhoneNumber}
													</h5>
												</div>
											</FormGroup>
										</div>

										{/* Optional email address and phone number */}
										<div className="col-12">
											<FormGroup>
												<Label >Other Email(optional)</Label>
												<Input type="text"
													invalid={errors.emailAddress1 && touched.emailAddress1}
													name="emailAddress1" onChange={handleChange} onBlur={handleBlur} value={values.emailAddress1} placeholder="Enter Email Address" />
												<FormFeedback>
													{errors.emailAddress1 && touched.emailAddress1 && errors.emailAddress1}
												</FormFeedback>
											</FormGroup>
										</div>

										<div className="col-12">
											<FormGroup>
												<Label >Other Phone Number(optional)</Label>
												<div class="input-group mb-3">
													<PhoneInput
														containerClassName="intl-tel-input"
														inputClassName="form-control"
														onPhoneNumberChange={(isEdited, phoneNumber, selectedCountry) => this.handlePhoneNumberChange(isEdited, phoneNumber, selectedCountry, setFieldValue, 'phoneNumber1')}
													/>
													<h5 className='error-display'>
														{!errors.phoneNumber1 && !touched.phoneNumber1 && !this.state.phoneNumber1IsValidPhoneNo && "Please enter a valid phone number"}
														{errors.phoneNumber1 && touched.phoneNumber1 && errors.phoneNumber1}
													</h5>
												</div>
											</FormGroup>
										</div>

										{values.type === 'SR' &&
											<div className="col-12">
												<FormGroup>
													<Label>Phone Number</Label>
													<Input type="text"
														readOnly
														invalid={errors.phoneNumber && touched.phoneNumber}
														name="phoneNumber" onChange={handleChange} onBlur={handleBlur} value={values.phoneNumber}
														placeholder="Enter phone number" />
													<FormFeedback>
														{errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
													</FormFeedback>
												</FormGroup>
											</div>

										}
										<div className="col-12">
											<FormGroup>
												<Label>Delivery Instruction</Label>
												<Input type="textarea"
													invalid={errors.comment && touched.comment}
													name="comment" onChange={handleChange} onBlur={handleBlur} value={values.comment}
													placeholder="Enter order number/ SKU No/ delivery instruction" />
												<FormFeedback>
													{errors.comment && touched.comment && errors.comment}
												</FormFeedback>
											</FormGroup>
										</div>



										<div className="col-12">
											<FormGroup>
												<Label>Shipment Description</Label>
												<Input type="textarea"
													invalid={errors.shipmentDescription && touched.shipmentDescription}
													name="shipmentDescription" onChange={handleChange} onBlur={handleBlur} value={values.shipmentDescription}
													placeholder="Enter content description" />
												<FormFeedback>
													{errors.shipmentDescription && touched.shipmentDescription && errors.shipmentDescription}
												</FormFeedback>
											</FormGroup>
										</div>

										{(values.modeOfTransport === "Road" || values.modeOfTransport === "Rail") && <div className="col-12">
											<FormGroup>
												<Label>Origin</Label>
												<Input type="text"
													invalid={errors.origin && touched.origin}
													name="origin" onChange={(e) => {
														handleChange(e)
													}} onBlur={handleBlur} value={values.origin}
													placeholder="Enter origin" />
												<FormFeedback>
													{errors.origin && touched.origin && errors.origin}
												</FormFeedback>
											</FormGroup>
										</div>}

										{(values.modeOfTransport === "Road" || values.modeOfTransport === "Rail") && <div className="col-12">
											<FormGroup>
												<Label>Destination</Label>
												<Input type="text"
													invalid={errors.destination && touched.destination}
													name="destination" onChange={(e) => {
														handleChange(e)
													}} onBlur={handleBlur} value={values.destination}
													placeholder="Enter destination" />
												<FormFeedback>
													{errors.destination && touched.destination && errors.destination}
												</FormFeedback>
											</FormGroup>
										</div>}

										{/* // origin for sea and air */}
										{(values.modeOfTransport === "Sea" || values.modeOfTransport === "Air") && <div className="col-12">
											<FormGroup>
												<Label>Origin</Label>
												<Select
													name="origin"
													onChange={(option) => this.fetchOriginCountry(option, 'origin', values, setFieldValue)}
													onBlur={handleBlur}
													className="is-invalid"
													value={values.modeOfTransport === "Air" ? cityList.filter(option => values.origin === option.value) :
														seaPort.filter(option => values.origin === option.value)}
													options={values.modeOfTransport === "Air" ? cityList : seaPort}
												/>
												<FormFeedback>
													{errors.origin && touched.origin && errors.origin}
												</FormFeedback>
											</FormGroup>
										</div>}

										{(values.modeOfTransport === "Sea" || values.modeOfTransport === "Air") && <div className="col-12">
											<FormGroup>
												<Label>Destination</Label>
												<Select
													name="destination"
													onChange={(option) => this.fetchOriginCountry(option, 'destination', values, setFieldValue)}
													onBlur={handleBlur}
													className="is-invalid"
													value={values.modeOfTransport === "Air" ? cityList.filter(option => values.destination === option.value) :
														seaPort.filter(option => values.destination === option.value)
													}
													options={values.modeOfTransport === "Air" ? cityList : seaPort}
												/>
												<FormFeedback>
													{errors.destination && touched.destination && errors.destination}
												</FormFeedback>
											</FormGroup>
										</div>}

										<div className="col-12">
											<FormGroup>
												<Label>Origin Country</Label>
												<Select
													name="origin_country"
													onChange={(option) => setFieldValue('origin_country', option.value)}
													onBlur={handleBlur}
													className="is-invalid"
													value={options.filter(option => values.origin_country === option.value)}
													options={options}
												/>
												<FormFeedback>
													{errors.origin_country && touched.origin_country && errors.origin_country}
												</FormFeedback>
											</FormGroup>
										</div>

										<div className="col-12">
											<FormGroup>
												<Label>Destination Country</Label>
												<Select
													name="destination_country"
													onChange={(option) => setFieldValue('destination_country', option.value)}
													onBlur={handleBlur}
													className="is-invalid"
													value={options.filter(option => values.destination_country === option.value)}
													options={options}
												/>
												<FormFeedback>
													{errors.destination_country && touched.destination_country && errors.destination_country}
												</FormFeedback>
											</FormGroup>
										</div>


										<div className="col-12">
											<FormGroup>
												<Label>Carrier Name</Label>
												<Input type="text"
													invalid={errors.carrierName && touched.carrierName}
													name="carrierName" onChange={handleChange} onBlur={handleBlur} value={values.carrierName}
													placeholder="Enter Carrier Name" />
												<FormFeedback>
													{errors.carrierName && touched.carrierName && errors.carrierName}
												</FormFeedback>
											</FormGroup>
										</div>

										<div className="col-12">
											<FormGroup>
												<Label>Pick Up</Label>
												<Input type="textarea"
													invalid={errors.pickup && touched.pickup}
													name="pickup" onChange={handleChange} onBlur={handleBlur} value={values.pickup}
													placeholder="Enter pickup" />
												<FormFeedback>
													{errors.pickup && touched.pickup && errors.pickup}
												</FormFeedback>
											</FormGroup>
										</div>

										<div className="row m-0">
											<div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
												<FormGroup>
													<Label>Delivery Address</Label>
													<Input type="text"
														invalid={errors.deliveryAddress && touched.deliveryAddress}
														name="deliveryAddress" onChange={(e) => { handleChange(e); this.changeDeliveryHandler(e) }} onBlur={handleBlur}
														value={values.deliveryAddress} placeholder="Enter Delivery Address" />
													<FormFeedback>
														{errors.deliveryAddress && touched.deliveryAddress && errors.deliveryAddress}
													</FormFeedback>
												</FormGroup>
											</div>

											<div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 d-flex align-items-center mt-3 mb-3 mb-lg-0 mb-xl-0">
												{this.state.addressButton ?
													<button className="btn btn-submit address-btn" onClick={() => this.addressSaveHandler(values.deliveryAddress)}>
														{this.state.addressLoading ?
															< ClipLoader
																size={15}
																color={"#123abc"}
																loading={true}
															/> : 'Save to address book'
														}
													</button>
													:
													<button className="btn btn-submit address-btn" onClick={this.addressBookHandler}>Choose Address</button>
												}
											</div>
										</div>
										<div className="col-12">
											<FormGroup>
												<label className="form-control-label">Document</label>
												<FormGroup>
													<input accept="application/pdf, .jpeg, .png" hidden ref={this.input} type="file" multiple
														onChange={this.onSelectFile(values.invoice, setFieldValue)} />
													<FormFeedback>
														{errors.invoice && touched.invoice && errors.invoice}
													</FormFeedback>
													<button className="btn btn-primary"
														onClick={e => this.input.current && this.input.current.click()}>Browse
													</button>
												</FormGroup>
												<div className="d-flex overflow-auto">
													{this.state.filesData.map((p, i) => {
														return (
															<div className="d-flex flex-column m-2" key={i}>
																<div style={{ position: "relative", left: "20px" }}>
																	<button className="btn btn-link" onClick={() => this.imageRemoveHandler(i, setFieldValue)}
																		style={{ position: "absolute", paddingLeft: "40px", alignSelf: "center" }}><img
																			src={close} alt="" /></button>
																</div>
																<img alt={this.state['documentImage' + p]} style={{ width: "100px", height: "100px", border: "2px solid rgb(179, 179, 179, 0.2)" }} src={p.type == 'application/pdf' ? pdfImage : p.type.includes("image/") !== false ? URL.createObjectURL(p.filesInfo) : downloadIcon} />
																<span classes="mt-2">{trim(p.name, 13)}</span>
																<Input type="text"

																	name={`p.${i}.imageName`} onChange={(e) => { this.handleInputChange(i, e, 'imageName', setFieldValue) }} onBlur={handleBlur}
																	value={p.imageName ? p.imageName : p.name} placeholder="Enter Name of file" />

																<Input type="text"
																	name={`p.${i}.imageDescription`} onChange={(e) => { this.handleInputChange(i, e, 'imageDescription', setFieldValue) }} onBlur={handleBlur}
																	value={p.imageDescription} placeholder="Enter Description" />
															</div>
														)
													})}
												</div>

											</FormGroup>
											{this.state.filesData.length > 0 ? <div className="col-12 pt-3">
												<FormGroup check>
													<Label check>
														<Input type="checkbox" placeholder="" onChange={() => this.changeCheckbox(setFieldValue, values.hideAdCodes)} />{' '}
														Uploaded document(s) should be restricted to a list of Postkodes users {values.hideAdCodes}
													</Label>
												</FormGroup>
											</div> : null}
											{values.hideAdCodes && <div className="col-12">
												<FormGroup>
													<Label>Enter Adcodes</Label>
													<TagsInput
														value={this.state.tags}
														onChange={(tags) => this.handleTags(tags, setFieldValue)}
														renderInput={this.defaultRenderInput}
														pasteSplit={this.pasteSplit}
														addOnPaste={true}
														onlyUnique={true}
														renderTag={this.renderTags}
														addKeys={[9, 13, 188]}
														inputProps={{
															className: 'react-tagsinput-input',
															placeholder: ''
														}}
													/>
													<Input
														type="hidden"
														invalid={(errors.adCodes && touched.adCodes) || this.state.customErr}
														name="adCodes"
														onBlur={handleBlur}
														value={values.adCodes}
													/>
													<FormFeedback>
														{errors.adCodes && touched.adCodes && errors.adCodes}
													</FormFeedback>
												</FormGroup>
											</div>}
										</div>

										<div className="d-flex justify-content-center">
											<button className="btn btn-submit" onClick={handleSubmit}>
												{this.state.loading ?
													< ClipLoader
														size={15}
														color={"#123abc"}
														loading={true}
													/> : 'Save'
												}
											</button>
										</div>
									</CardBody>
								</Card>
							</div>
						</>
					)}
				</Formik >
			</div >
		)
	}
}


import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css'
import React, { Component, Fragment } from 'react'
import { CardHeader, CardBody, FormGroup, Label, Input, Card, FormFeedback, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import Select from 'react-select';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ClipLoader } from "react-spinners";
import { phoneNoVerifyCheck, tsid } from '../../utils/utils';
import { assignMasterNumberApi, validateRefNums, requestAccess, checkScreenAccess } from '../../api/Form';
import { toast } from 'react-toastify';
import Modals from './Modal';
import { callActivityApi } from '../../utils/saveUserActivity';

const Schema = Yup.object().shape({
  refno: Yup.string()
    .required('Tracking number is required'),
  airwayBill: Yup.string()
    .min(8, 'Master Airwaybill number must be exactly 8 digits')
    .max(20, 'Master Airwaybill number must be exactly 20 digits')
    .matches(
      /^[a-zA-Z0-9]+$/,
      "No special characters"
    )
    .required('Master Airwaybill number is required'),
});

const modeOfTransportOption = [
  { value: 'Air', label: 'Air' },
  { value: 'Sea', label: 'Sea' },
  { value: 'Road', label: 'Road' },
  { value: 'Rail', label: 'Rail' }
]

export default class AssignMasterNumber extends Component {
  input = React.createRef();
  state = {
    deviceADcode: '',
    airwayBill: '',
    modeOfTransport: '',
    scan: [],
    userId: '',
    tags: [],
    errorTags: [],
    editTags: [],
    overrideInfo: [],
    editActiveValue: '',
    loading: true,
    customErr: '',
    requestAccess: false,
    isConfirm: false,
    overrideMessage: '',
  }

  async componentDidMount() {
    callActivityApi({ 'pageName': 'Assign Master Number', 'description': 'User has visited Assign master Screen' });
    const historyPush = this.props.history.push
    phoneNoVerifyCheck(historyPush)
    const deviceADcode = await localStorage.getItem('adCodes');
    this.setState({ deviceADcode })
    const userId = await localStorage.getItem('userId');
    this.setState({ userId })
    var userType = await localStorage.getItem('accounttypeUsers');
    if (userType !== 'Admin') {
      checkScreenAccess(
        {
          'userId': userId,
          'screenType': 'Assign Master Number'
        }
      ).then((response) => {
        this.setState({ loading: false, requestAccess: true });
      }).catch(err => {
        this.setState({ loading: false, requestAccess: false });
      });
    } else {
      this.setState({ requestAccess: true, loading: false })
    }
  }

  handleTags = (tags, setFieldValue) => {
    this.setState({ loading: true });
    //call api to check here info
    let data = {
      ref_nums: tags,
      is_master_page: true,
    };
    validateRefNums(data)
      .then(Response => {

        if (Response?.status === 200) {
          const mot = Response?.data?.data[0]
          this.setState({ loading: false, tags: tags, customErr: '', modeOfTransport: mot, refno: tags.toString() });
          // setFieldValue('refno', tags.toString());
        }

      }).catch(err => {
        this.setState({ loading: false });
        let message = err.message;
        if (err.response && err.response.data.message) {
          message = err.response.data.message;
          this.setState({ errorTags: (err.response.data.data === null) ? [] : err.response.data.data, tags: tags, customErr: message });
          setFieldValue('refno', tags.toString());
        }
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT
        });
      });

  };
  checkEdit = (key) => {
    let editTags = [];
    editTags.push(this.state.tags[key]);
    this.setState({ editTags, editActiveValue: this.state.tags[key] });
  };

  handleEdit = (value, key) => {
    // console.log(value);
    let tags = this.state.tags;
    tags[key] = value;
    this.setState({ loading: true, tags, editActiveValue: '', editTags: [] });
    //call api to check here info
    let data = {
      ref_nums: tags,
      is_master_page: true
    };
    validateRefNums(data)
      .then(Response => {
        //console.log(Response);
        this.setState({ loading: false, tags: tags, customErr: '' });
        //setFieldValue('refno', tags.toString());
      }).catch(err => {
        this.setState({ loading: false });
        let message = err.message;
        if (err.response && err.response.data.message) {
          message = err.response.data.message;
        }
        this.setState({ errorTags: (err.response.data.data === null) ? [] : err.response.data.data, tags: tags, customErr: message });
        //setFieldValue('refno', tags.toString());
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  };

  pasteSplit = (data) => {
    const separators = [',', ';', '\\(', '\\)', '\\*', '/', ':', '\\?', '\n', '\r', 'g'];
    return data.split(new RegExp(separators.join('|'))).map(d => d.trim());
  };
  renderTags = (props) => {
    // console.log(this.state.errorTags);
    let { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;
    let classvar = { ...other };
    if (this.state.errorTags.includes(getTagDisplayValue(tag))) {
      classvar.className = "react-tagsinput-tag error";
    }
    if (this.state.editTags.includes(getTagDisplayValue(tag))) {
      return (
        <Input
          name={'edit' + key}
          onChange={(tags) => {
            this.setState({ editActiveValue: tags.target.value });
          }}
          onKeyPress={event => {
            //console.log(event.key);
            if (event.key === 'Enter') {
              this.handleEdit(event.target.value, key);
            }
          }}
          className={'editTag'}
          value={this.state.editActiveValue}
        />
      )
    } else {
      return (
        <span key={key} {...classvar} >
          <span onClick={() => this.checkEdit(key)}>{getTagDisplayValue(tag)}</span>
          {!disabled &&
            <a className={classNameRemove} onClick={(e) => onRemove(key)} />
          }
        </span>
      )
    }
  };

  submitHandler = (values, modeOfTransport) => {

    if (!this.state.customErr) {
      this.setState({ loading: true, airwayBill: values, modeOfTransport: modeOfTransport });
      assignMasterNumberApi({
        airwayBill: values,
        userId: this.state.userId,
        ref_nums: this.state.tags,
        modeOfTransport: modeOfTransport,
        override: 0,
      }).then(Response => {
        this.setState({ loading: false });
        const overrideInfo = Response.data.data;

        if (overrideInfo.length > 0) {
          this.setState({
            isConfirm: true, overrideInfo,
            overrideMessage: 'Master airwaybill number already assigned to ' + overrideInfo.toString() + ', do you want to override ?'
          });
        } else {
          toast.success(Response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
          this.props.history.push('/admin/index');
        }

      }).catch(err => {
        this.setState({ loading: false });
        let message = err.message;
        if (err.response && err.response.data.message) {
          message = err.response.data.message;
        }
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT
        });
      })
    }
  }

  overrideHandler = () => {
    this.setState({ loading: true, isConfirm: false });
    assignMasterNumberApi({
      airwayBill: this.state.airwayBill,
      userId: this.state.userId,
      ref_nums: this.state.overrideInfo,
      modeOfTransport: this.state.modeOfTransport,
      override: 1,
    }).then(Response => {
      this.setState({ loading: false });
      toast.success(Response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      this.props.history.push('/admin/index');
    }).catch(err => {
      this.setState({ loading: false });
      let message = err.message;
      if (err.response && err.response.data.message) {
        message = err.response.data.message;
      }
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })
  }

  sendRequestAccess = async () => {
    this.setState({ loading: true });
    const info = await localStorage.getItem('auth');
    const sendInfo = JSON.parse(info);
    requestAccess({
      'username': sendInfo.username,
      'firstName': sendInfo.firstName,
      'lastName': sendInfo.lastName,
      'accounttypeUsers': sendInfo.accounttypeUsers,
      'businessname': sendInfo.businessname,
      'ADCode': sendInfo.ADCode,
      'address': sendInfo.address,
      'email': sendInfo.email,
      'phoneNumber': sendInfo.phonenumber,
      'userId': sendInfo.id,
      'screenType': 'Assign Master Number',
    }).then(Response => {
      this.setState({ loading: false });
      toast.success(Response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }).catch(err => {
      this.setState({ loading: false });
      let message = err.message;
      if (err.response && err.response.data.message) {
        message = err.response.data.message;
      }
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })

  };

  defaultRenderInput = (props) => {
    let { onChange, value, addTag, ...other } = props
    return (
      <input type='text' onChange={(e) => { onChange(e); this.setState({ editTags: [] }) }} value={value} {...other} />
    )
  }

  render() {
    const { scan, requestAccess } = this.state;
    if (this.state.isConfirm) {
      return (
        <Modals
          overrideInfo={this.state.overrideInfo}
          overrideHandler={this.overrideHandler}
          overrideMessage={this.state.overrideMessage}
          close={this.props.history} />
      )
    }
    return (
      <div>
        {!requestAccess ?
          <div className="justify-content-center pt-2 main-contain-title">
            <>
              <p>This feature is restricted.</p>
              <Button onClick={() => this.sendRequestAccess()}
                disabled={this.state.loading}>Request Access for free</Button>
            </>
          </div>
          :
          <>
            <div className="d-flex justify-content-center scan-parcel-loader">
              <ClipLoader
                size={50}
                color={"#123abc"}
                loading={this.state.loading}
              />
            </div>
            <Formik
              enableReinitialize
              initialValues={{
                refno: this.state.refno,
                airwayBill: this.state.airwayBill,
                modeOfTransport: this.state.modeOfTransport
              }}
              validationSchema={Schema}
              onSubmit={(values, { setSubmitting, setErrors }) => {
                this.setErrors = setErrors;
                this.submitHandler(values.airwayBill, values.modeOfTransport);
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                handleBlur,
                setFieldValue,
                status,
                isSubmitting, }) => (


                <Fragment Fragment >
                  <div className={this.state.loading ? "mt-2 style loadingDiv" : "mt-2 style"}>
                    <Card>
                      <CardHeader className="c-header">Assign Master Tracking Number</CardHeader>
                      <CardBody>


                        <div className="col-12">
                          <FormGroup>
                            <Label>Tracking Numbers</Label>
                            <TagsInput
                              value={this.state.tags}
                              onChange={(tags) => this.handleTags(tags, setFieldValue)}
                              renderInput={this.defaultRenderInput}
                              pasteSplit={this.pasteSplit}
                              addOnPaste={true}
                              onlyUnique={true}
                              renderTag={this.renderTags}
                              addKeys={[9, 13, 188]}
                              inputProps={{
                                className: 'react-tagsinput-input',
                                placeholder: 'Enter tracking numbers'
                              }}
                            />
                            <Input
                              type="hidden"
                              invalid={(errors.refno && touched.refno) || this.state.customErr}
                              name="refno"
                              onBlur={handleBlur}
                              value={values.refno}
                            />
                            <FormFeedback>
                              {(errors.refno && touched.refno && errors.refno) || this.state.customErr}
                            </FormFeedback>
                          </FormGroup>
                        </div>


                        <div className='col-12'><FormGroup>
                          <Label>Mode of Transport (MOT)</Label>
                          <Select
                            name="modeOfTransport"
                            onChange={(option) => setFieldValue('modeOfTransport', option.value)}
                            onBlur={handleBlur}
                            className="is-invalid"
                            value={modeOfTransportOption.filter(option => values.modeOfTransport === option.value)}
                            options={modeOfTransportOption}
                            isDisabled={true}
                          />
                          <FormFeedback>
                            {errors.modeOfTransport && touched.modeOfTransport && errors.modeOfTransport}
                          </FormFeedback>
                        </FormGroup></div>

                        <div className="col-12">
                          <FormGroup>
                            <Label>{values.modeOfTransport === "Air" ? "Master Airwaybill" :
                              values.modeOfTransport === "Sea" ? "Master Bill of Lading/ Master seaway bill" : "Courier Manifest No"}</Label>
                            <Input
                              type="text"
                              invalid={errors.airwayBill && touched.airwayBill}
                              name="airwayBill"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.airwayBill}
                              placeholder={values.modeOfTransport === "Air" ? "Enter Master Airwaybill" :
                                values.modeOfTransport === "Sea" ? "Enter Master Bill of Lading/ Master seaway bill" : "Enter Courier Manifest No"} />
                            <FormFeedback>
                              {errors.airwayBill && touched.airwayBill && errors.airwayBill}
                            </FormFeedback>
                          </FormGroup>
                          <button className="btn btn-generate mb-3" onClick={() => { setFieldValue('airwayBill', 'M' + tsid(9)) }}>Generate</button>
                        </div>



                        <div className="d-flex justify-content-center">
                          <button className="btn btn-submit" onClick={handleSubmit} >Submit</button>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </Fragment>
              )}
            </Formik>
          </>
        }
      </div>
    )
  }
}
